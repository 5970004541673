@import '../../style/themes/index';
@import '../../style/mixins/index';

@tag-prefix-cls: ~'@{ant-prefix}-tag';

.@{tag-prefix-cls} {
  .reset-component();

  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: @tag-font-size;
  line-height: @tag-line-height;
  white-space: nowrap;
  background: @tag-default-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-radius: @border-radius-base;
  opacity: 1;
  transition: all 0.3s;

  &,
  a,
  a:hover {
    color: @tag-default-color;
  }

  > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }

  &-close-icon {
    margin-left: 3px;
    color: @text-color-secondary;
    font-size: 10px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: @heading-color;
    }
  }

  &-has-color {
    border-color: transparent;
    &,
    a,
    a:hover,
    .@{iconfont-css-prefix}-close,
    .@{iconfont-css-prefix}-close:hover {
      color: @text-color-inverse;
    }
  }

  &-checkable {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &:not(&-checked):hover {
      color: @primary-color;
    }

    &:active,
    &-checked {
      color: @text-color-inverse;
    }

    &-checked {
      background-color: @primary-6;
    }

    &:active {
      background-color: @primary-7;
    }
  }

  &-hidden {
    display: none;
  }

  // mixin to iterate over colors and create CSS class for each one
  .make-color-classes(@i: length(@preset-colors)) when (@i > 0) {
    .make-color-classes(@i - 1);
    @color: extract(@preset-colors, @i);
    @lightColor: '@{color}-1';
    @lightBorderColor: '@{color}-3';
    @darkColor: '@{color}-6';
    @textColor: '@{color}-7';
    &-@{color} {
      color: @@textColor;
      background: @@lightColor;
      border-color: @@lightBorderColor;
    }
    &-@{color}-inverse {
      color: @text-color-inverse;
      background: @@darkColor;
      border-color: @@darkColor;
    }
  }

  .make-status-color-classes(@status, @cssVariableType) {
    @bgColor: '@{cssVariableType}-color-deprecated-bg';
    @borderColor: '@{cssVariableType}-color-deprecated-border';
    @textColor: '@{cssVariableType}-color';
    &-@{status} {
      color: @@textColor;
      background: @@bgColor;
      border-color: @@borderColor;
    }
  }

  .make-color-classes();

  .make-status-color-classes(success, success);
  .make-status-color-classes(processing, info);
  .make-status-color-classes(error, error);
  .make-status-color-classes(warning, warning);

  // To ensure that a space will be placed between character and `Icon`.
  > .@{iconfont-css-prefix} + span,
  > span + .@{iconfont-css-prefix} {
    margin-left: 7px;
  }
}

@import './rtl';

@root-entry-name: variable;