@import '../../style/themes/index';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{ant-prefix}-collapse';

.@{collapse-prefix-cls} {
  .reset-component();

  background-color: @collapse-header-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-bottom: 0;
  border-radius: @collapse-panel-border-radius;

  & > &-item {
    border-bottom: @border-width-base @border-style-base @border-color-base;

    &:last-child {
      &,
      & > .@{collapse-prefix-cls}-header {
        border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
      }
    }

    > .@{collapse-prefix-cls}-header {
      position: relative; // Compatible with old version of antd, should remove in next version
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding: @collapse-header-padding;
      color: @heading-color;
      line-height: @line-height-base;
      cursor: pointer;
      transition: all 0.3s, visibility 0s;

      .@{collapse-prefix-cls}-arrow {
        display: inline-block;
        margin-right: @margin-sm;
        font-size: @font-size-sm;
        vertical-align: -1px;

        & svg {
          transition: transform 0.24s;
        }
      }

      .@{collapse-prefix-cls}-extra {
        margin-left: auto;
      }

      &:focus {
        outline: none;
      }
    }

    .@{collapse-prefix-cls}-header-collapsible-only {
      cursor: default;
      .@{collapse-prefix-cls}-header-text {
        cursor: pointer;
      }
    }

    &.@{collapse-prefix-cls}-no-arrow {
      > .@{collapse-prefix-cls}-header {
        padding-left: @padding-sm;
      }
    }
  }

  // Expand Icon right
  &-icon-position-right {
    & > .@{collapse-prefix-cls}-item {
      > .@{collapse-prefix-cls}-header {
        position: relative;
        padding: @collapse-header-padding;
        padding-right: @collapse-header-padding-extra;

        .@{collapse-prefix-cls}-arrow {
          position: absolute;
          top: 50%;
          right: @padding-md;
          left: auto;
          margin: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  &-content {
    color: @text-color;
    background-color: @collapse-content-bg;
    border-top: @border-width-base @border-style-base @border-color-base;

    & > &-box {
      padding: @collapse-content-padding;
    }

    &-hidden {
      display: none;
    }
  }

  &-item:last-child {
    > .@{collapse-prefix-cls}-content {
      border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
    }
  }

  &-borderless {
    background-color: @collapse-header-bg;
    border: 0;
  }

  &-borderless > &-item {
    border-bottom: 1px solid @border-color-base;
  }

  &-borderless > &-item:last-child,
  &-borderless > &-item:last-child &-header {
    border-radius: 0;
  }

  &-borderless > &-item > &-content {
    background-color: transparent;
    border-top: 0;
  }

  &-borderless > &-item > &-content > &-content-box {
    padding-top: 4px;
  }

  &-ghost {
    background-color: transparent;
    border: 0;
    > .@{collapse-prefix-cls}-item {
      border-bottom: 0;
      > .@{collapse-prefix-cls}-content {
        background-color: transparent;
        border-top: 0;
        > .@{collapse-prefix-cls}-content-box {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  & &-item-disabled > &-header {
    &,
    & > .arrow {
      color: @disabled-color;
      cursor: not-allowed;
    }
  }
}

@import './rtl';

@root-entry-name: variable;