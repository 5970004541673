@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './mixin';

// Grid system
.@{ant-prefix}-row {
  display: flex;
  flex-flow: row wrap;

  &::before,
  &::after {
    display: flex;
  }

  // No wrap of flex
  &-no-wrap {
    flex-wrap: nowrap;
  }
}

// x轴原点
.@{ant-prefix}-row-start {
  justify-content: flex-start;
}

// x轴居中
.@{ant-prefix}-row-center {
  justify-content: center;
}

// x轴反方向
.@{ant-prefix}-row-end {
  justify-content: flex-end;
}

// x轴平分
.@{ant-prefix}-row-space-between {
  justify-content: space-between;
}

// x轴有间隔地平分
.@{ant-prefix}-row-space-around {
  justify-content: space-around;
}

// 顶部对齐
.@{ant-prefix}-row-top {
  align-items: flex-start;
}

// 居中对齐
.@{ant-prefix}-row-middle {
  align-items: center;
}

// 底部对齐
.@{ant-prefix}-row-bottom {
  align-items: flex-end;
}

.@{ant-prefix}-col {
  position: relative;
  max-width: 100%;
  // Prevent columns from collapsing when empty
  min-height: 1px;
}

.make-grid();

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(-xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
  .make-grid(-sm);
}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
  .make-grid(-md);
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {
  .make-grid(-lg);
}

// Extra Large grid
//
// Columns, offsets, pushes, and pulls for the full hd device range.

@media (min-width: @screen-xl-min) {
  .make-grid(-xl);
}

// Extra Extra Large grid
//
// Columns, offsets, pushes, and pulls for the full hd device range.

@media (min-width: @screen-xxl-min) {
  .make-grid(-xxl);
}

@import './rtl';

@root-entry-name: variable;