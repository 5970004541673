@import '../../style/themes/index';
@import '../../style/mixins/index';

@rate-prefix-cls: ~'@{ant-prefix}-rate';

.@{rate-prefix-cls} {
  .reset-component();

  display: inline-block;
  margin: 0;
  padding: 0;
  color: @rate-star-color;
  font-size: @rate-star-size;
  line-height: unset;
  list-style: none;
  outline: none;

  &-disabled &-star {
    cursor: default;

    &:hover {
      transform: scale(1);
    }
  }

  &-star {
    position: relative;
    display: inline-block;
    color: inherit;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }

    > div {
      transition: all 0.3s, outline 0s;

      &:hover,
      &:focus-visible {
        transform: @rate-star-hover-scale;
      }

      &:focus {
        outline: 0;
      }

      &:focus-visible {
        outline: 1px dashed @rate-star-color;
      }
    }

    &-first,
    &-second {
      color: @rate-star-bg;
      transition: all 0.3s;
      user-select: none;
      .@{iconfont-css-prefix} {
        vertical-align: middle;
      }
    }

    &-first {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
    }

    &-half &-first,
    &-half &-second {
      opacity: 1;
    }

    &-half &-first,
    &-full &-second {
      color: inherit;
    }
  }

  &-text {
    display: inline-block;
    margin: 0 8px;
    font-size: @font-size-base;
  }
}

@import './rtl';

@root-entry-name: variable;